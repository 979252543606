interface ResourcePermission {
    read: string[]
    write: string[]
}


const AppManagerPermissions: Record<string, ResourcePermission> = {
    users: {
        read: ["user.read",],
        write: ["user.write"]
    },
    roles: {
        read: ["group.read", "permission.read", "access.read"],
        write: ["group.write", "permission.write", "access.write"]
    },
    clinicalStudies: {
        read: ["clinical_study.read"],
        write: ["clinical_study.write"]
    },
    audits: {
        read: ["user_log.read"],
        write: ["user_log.write"]
    },
    laboratories: {
        read: ["laboratory.read"],
        write: ["laboratory.write"]
    },
    fileTypes: {
        read: ["file_type.read"],
        write: ["file_type.write"]
    }
}

const ClinicalDataPermissions: Record<string, ResourcePermission> = {
    clinicalData: {
        read: ["clinical_data.read"],
        write: []
    },
    sourceFiles: {
        read: ["file_study_data.read"],
        write: ["file_study_data.write"]
    }
}

const DocumentsPermissions: Record<string, ResourcePermission> = {
    documents: {
        read: ["document.read"],
        write: ["document.write"]
    }
}

const LabTestPermissions: Record<string, ResourcePermission> = {
    labTest: {
        read: ["laboratory_test_file.read"],
        write: ["laboratory_test_file.write"]
    },
    metadataFiles: {
        read: ["laboratory_source_file.read"],
        write: ["laboratory_source_file.write"]
    }
}
const HomePermissions: Record<string, ResourcePermission> = {
    profile: {
        read: [],
        write: []
    },
    downloadRequests: {
        read: ["laboratory_test_file.read"],
        write: [],
    }
}

export const Permissions: Record<string, Record<string, ResourcePermission>> = {
    appManager: AppManagerPermissions,
    clinical: ClinicalDataPermissions,
    documents: DocumentsPermissions,
    lab: LabTestPermissions,
    home: HomePermissions,
}


export enum PermissionLevel {
    NONE = 'none',
    ADMIN = 'admin',
    DOWNLOAD = 'download',
    EXTERNAL = 'external'
}

export enum DocumentsPermissionLevel {
    NONE = 'none',
    ADMIN = 'admin',
    EDITOR = 'editor',
    VIEWER = 'viewer'
}
